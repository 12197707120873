<template>
  <div>
    <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :placeholder="placeholder" v-model.number="inputValue" :controls="false" @change="setValue" :disabled="disabled" @blur="inputBlurChange">
      <el-select v-model="type" slot="prepend" placeholder="请选择" style="width: 110px" v-if="showType === 'search'" @change="setValue">
        <el-option
          v-for="item in SEARCH_FORM_NUMRANGE"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-input>
  </div>
</template>

<script>
  import { SEARCH_FORM_NUMRANGE } from '../../constant';

  export default {
    name: 'comNumRange',
    props:{
      value:{
        type: [Array,String,Number]
      },
      placeholder:{
        type: String
      },
      disabled:{
        type: Boolean
      },
      /*
      * 展示类型
      * search =>  页面查询数据，作为查询条件时
      * info => 数据增删改查详情数据时
      * */
      showType:{
        type: String
      }
    },
    data() {
      return {
        type:'SINGLE_VALUE',
        inputValue:'',
        SEARCH_FORM_NUMRANGE:SEARCH_FORM_NUMRANGE
      }
    },
    watch:{
      value(){
        this.init()
      }
    },
    mounted() {
      this.$nextTick(()=>{
        this.init();
      })
    },
    methods: {
      init(){
        if(Array.isArray(this.value)){
          if(this.value.length){
            this.type = this.value[0];
            this.inputValue = this.value[1];
          }
        }else{
          this.inputValue = this.value
        }
      },
      inputBlurChange(){
        if(this.showType === 'search'){
          this.$emit('input',[this.type,this.inputValue])
        }else{
          this.$emit('input',this.inputValue)
        }
      },
      setValue(){
        if(this.showType === 'search'){
          this.$emit('input',[this.type,this.inputValue])
        }else{
          this.$emit('input',this.inputValue)
        }
      }
    }
  }
</script>

<style scoped>

</style>
